import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
    isAuthenticated: boolean;
    user: {};
  }
//token,firstname,lastname,role,wallet,
const initialState:AuthState = {
  isAuthenticated: false,
  user: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginR: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logoutR: (state) => {
      state.isAuthenticated = false;
      state.user = {};
    },
  },
});

export const { loginR, logoutR } = authSlice.actions;
export default authSlice.reducer;
export type { AuthState };
