const Loader = () => {
    return (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-2">
            <div className="relative">
                <div className="w-20 h-20 border-[#DDFFC8] border-2 rounded-full"></div>
                <div className="w-20 h-20 border-[#26680C] border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
            </div>

            <div className="relative">
                <div className="w-10 h-10 border-[#DDFFC8] border-2 rounded-full"></div>
                <div className="w-10 h-10 border-[#26680C] border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
            </div>

            <div className="relative">
                <div className="w-5 h-5 border-[#DDFFC8] border-2 rounded-full"></div>
                <div className="w-5 h-5 border-[#26680C] border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
            </div>
        </div>
    );
};

export default Loader;
