export enum Path {
  Home = '/',
  Login = '/payadminlogin',
  Dashboard = '/payverdeadmin',
  Merchant = '/merchant',
  MerchantLogin = '/merchant-login',
  MerchantSignup = '/merchant-signup',
  MerchantForgotPassword = '/merchant-forgotpassword',
  MerchantResetPassword = '/merchant-resetpassword',

  MerchantDashboard = '/merchant/merchant-dashboard',
  MerchantWalletDashboard = '/merchant/merchant-wallet',
  MerchantInventoryDashboard = '/merchant/merchant-inventory',
  MerchantTransactionDashboard = '/merchant/merchant-transaction',
  MerchantPaymentDashboard = '/merchant/merchant-payment',
  MerchantReportDashboard = '/merchant/merchant-report',
  MerchantSettingsDashboard = '/merchant/merchant-settings',
  MerchantTeamDashboard = '/merchant/merchant-team',
  MerchantSupportDashboard = '/merchant/merchant-support',

}
