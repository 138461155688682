// import { configureStore } from '@reduxjs/toolkit';
// // import thunk from 'redux-thunk';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import rootReducer from './reducers';

// const persistConfig = {
//     key: 'root',
//     storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//     reducer: persistedReducer,
//     // middleware: (getDefaultMiddleware) =>
//         // getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
//     middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware({
//           serializableCheck: false,
//           thunk: true,
//         }),
// });

// const persistor = persistStore(store);

// export { store, persistor };
// src/store.ts or src/hooks.ts

import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers'; // Assuming you have the root reducer file

// Set up persist configuration
const persistConfig = {
    key: 'root',
    storage,
};

// Persist the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            thunk: true, // Thunk middleware is included by default
        }),
});

// Types for store, dispatch, and RootState
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Typed hooks for useDispatch and useSelector
export const useAppDispatch: () => AppDispatch = () => useDispatch<AppDispatch>(); // Ensures correct AppDispatch type is used
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
