// // src/reducers.ts
// import { combineReducers, Reducer } from 'redux';
// import auth, { AuthState } from './auth';
// import contact, { ContactState } from './contactSlice'; // Import contact reducer

// interface RootState {
//     auth: AuthState;
//     //contact: ContactState; // Add contact state to RootState
// }

// const rootReducer: Reducer<RootState> = combineReducers({
//     auth,
//     //contact, // Include contact reducer
// });

// export default rootReducer;
// export type { RootState };

// src/reducers.ts 
import { combineReducers, Reducer } from 'redux';
import auth, { AuthState } from './auth';
import contact, { ContactState } from './contactSlice'; // Import contact reducer
//import merchant, { MerchantState } from './merchantSlice'; 
import merchants, {MerchantAuthState} from './merchant';

interface RootState {
    auth: AuthState;
    contact: ContactState; // Add contact state to RootState
 //merchant: MerchantState;
 merchants: MerchantAuthState;
}

const rootReducer: Reducer<RootState> = combineReducers({
    auth,
    contact, 
   // merchant,
    merchants
});

export default rootReducer;
export type { RootState };
