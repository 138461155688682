// // src/features/contactSlice.ts
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';

// // Define the type for contact data
// interface ContactData {
//     inquiryOption: string;
//     fullName: string;
//     email: string;
//     message: string;
// }

// // Define the ContactState interface
// export interface ContactState {
//     loading: boolean;
//     error: string | null;
//     success: boolean;
// }

// const initialState: ContactState = {
//     loading: false,
//     error: null,
//     success: false,
// };

// // Create an async thunk for submitting contact data
// export const submitContact = createAsyncThunk<void, ContactData>(
//     'contact/submitContact',
//     async (contactData) => {
//         await axios.post('https://payverde-test-0ba0ffb80fb8.herokuapp.com/payverde/api/v1/contact-us', contactData);
//     }
// );

// const contactSlice = createSlice({
//     name: 'contact',
//     initialState,
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(submitContact.pending, (state) => {
//                 state.loading = true;
//                 state.error = null;
//             })
//             .addCase(submitContact.fulfilled, (state) => {
//                 state.loading = false;
//                 state.success = true;
//             })
//             .addCase(submitContact.rejected, (state, action) => {
//                 state.loading = false;
//                 state.error = action.error.message || 'Failed to submit';
//             });
//     },
// });

// export default contactSlice.reducer;



// src/contactSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from '../../store/index'; // Import the dispatch type

interface ContactState {
    loading: boolean;
    error: string | null;
    success: boolean;
}

const initialState: ContactState = {
    loading: false,
    error: null,
    success: false,
};

// Contact form submission action
export const submitContactForm = ({ inquiryOption, fullName, emailAddress, message }: { inquiryOption: string; fullName: string; emailAddress: string; message: string }) => {
    return async (dispatch: AppDispatch) => {
        // Start loading
        dispatch(submitContactFormStart());

        try {
            const response = await axios.post('https://payverde-test-0ba0ffb80fb8.herokuapp.com/payverde/api/v1/contact-us', {
                inquiryOption,
                fullName,
                emailAddress,
                message,
            });
            // Success
            dispatch(submitContactFormSuccess());
        } catch (error: any) {
            // Handle error response
            dispatch(submitContactFormFailure(error.response.data));
        }
    };
};

// Slice for contact form state management
const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        submitContactFormStart(state) {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        submitContactFormSuccess(state) {
            state.loading = false;
            state.success = true;
        },
        submitContactFormFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        resetContactForm(state) {
            state.loading = false;
            state.error = null;
            state.success = false;
        },
    },
});

// Exporting the actions
export const {
    submitContactFormStart,
    submitContactFormSuccess,
    submitContactFormFailure,
    resetContactForm,
} = contactSlice.actions;

export default contactSlice.reducer;
export type { ContactState };
